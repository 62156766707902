import React from 'react'
import { Link } from 'gatsby'
import { GitHubIcon } from '../social-share/github-icon'
import { ThemeSwitch } from '../../components/theme-switch'

import './index.scss'

export const Top = ({ title, location, rootPath }) => {
  const isRoot = location.pathname === rootPath
  return (
    <div className="top">
      <div className="right-items">
        {/* {!isRoot && (
          <Link to={`/`} className="link">
            {title}
          </Link>
        )} */}
        <Link to={`/`} className="link">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="141px"
            height="40px"
            viewBox="0 0 2902.68 820.81"
            xmlSpace="preserve"
          >
            <path
              fill="#1B75BB"
              d="M995.178,297.148c-23.727,0-40.813,7.591-51.275,22.771c-10.459,15.048-15.688,40.115-15.688,75.196
                c0,29.974,4.976,51.173,14.929,63.607c9.942,12.444,27.292,18.661,52.035,18.661h94.52v48.219H992.5
                c-21.933,0-38.775-1.855-50.506-5.555c-11.999-3.445-23.478-9.688-34.444-18.754c-13.899-10.966-24.427-25.633-31.569-44.003
                c-7.015-17.983-10.521-39.54-10.521-64.663c0-48.473,10.454-84.506,31.373-108.108c20.917-23.214,53.322-34.828,97.2-34.828h95.664
                v47.456H995.178z"
            />
            <path
              fill="#1B75BB"
              d="M1114.005,384.975c0-47.332,11.288-82.588,33.858-105.81c22.2-23.087,56.379-34.632,102.561-34.632
                c46.299,0,80.547,11.799,102.742,35.398c22.198,23.465,33.292,59.384,33.292,107.724c0,48.595-11.094,84.634-33.292,108.096
                c-22.453,23.727-56.703,35.59-102.742,35.59c-46.941,0-81.38-11.863-103.323-35.59
                C1125.028,472.289,1114.005,435.354,1114.005,384.975z M1250.424,291.602c-24.494,0-42.931,7.838-55.299,23.513
                c-12.246,15.675-18.371,39.058-18.371,70.146c0,33.638,5.87,58.425,17.609,74.361c11.861,15.92,30.542,23.89,56.061,23.89
                c25.131,0,43.685-7.97,55.676-23.89c11.991-15.676,17.984-39.698,17.984-72.067c0-32.364-5.993-56.515-17.984-72.439
                C1294.109,299.439,1275.555,291.602,1250.424,291.602z"
            />
            <path
              fill="#1B75BB"
              d="M1425.488,249.692h72.709l111.546,198.228l-2.671-198.228h58.731v275.911h-66.589l-117.666-208.555
	              l2.676,208.555h-58.736V249.692z"
            />
            <path
              fill="#1B75BB"
              d="M1951.846,299.256h-141.78c-15.69,0-26.983,2.678-33.868,8.036c-7.012,5.741-10.513,14.987-10.513,27.743
                c0,9.69,2.986,17.156,8.989,22.386c6.502,4.977,15.559,7.461,27.166,7.461h75.956c31.641,0,54.094,5.998,67.358,17.994
                c13.127,12.363,19.695,32.517,19.695,60.449c0,28.19-7.592,48.921-22.773,62.186c-15.426,13.393-39.596,20.094-72.5,20.094h-152.885
                v-48.982h143.891c16.449,0,28.059-2.487,34.815-7.466c6.522-4.714,9.769-12.688,9.769-23.91c0-10.972-3.139-19.202-9.381-24.69
                c-6.264-4.719-16.715-7.078-31.387-7.078h-67.721c-31.385,0-54.343-6.37-68.887-19.125c-14.799-12.883-22.193-32.915-22.193-60.089
                c0-28.948,7.777-50.379,23.35-64.283c15.299-13.52,39.475-20.287,72.506-20.287h150.393V299.256z"
            />
            <path
              fill="#1B75BB"
              d="M2068.191,525.604h-60.082V249.692h60.082V525.604z"
            />
            <path
              fill="#1B75BB"
              d="M2355.93,299.256h-141.76c-15.686,0-26.983,2.678-33.878,8.036c-7.002,5.741-10.508,14.987-10.508,27.743
                c0,9.69,2.971,17.156,8.979,22.386c6.497,4.977,15.578,7.461,27.177,7.461h75.955c31.631,0,54.078,5.998,67.348,17.994
                c13.128,12.363,19.696,32.517,19.696,60.449c0,28.19-7.567,48.921-22.748,62.186c-15.446,13.393-39.611,20.094-72.521,20.094
                h-152.879v-48.982h143.874c16.476,0,28.079-2.487,34.836-7.466c6.513-4.714,9.759-12.688,9.759-23.91
                c0-10.972-3.129-19.202-9.387-24.69c-6.237-4.719-16.71-7.078-31.36-7.078h-67.741c-31.391,0-54.343-6.37-68.892-19.125
                c-14.789-12.883-22.188-32.915-22.188-60.089c0-28.948,7.771-50.379,23.35-64.283c15.304-13.52,39.474-20.287,72.511-20.287h150.377
                V299.256z"
            />
            <path
              fill="#EC1C24"
              d="M2471.516,298.486H2383.3v-48.21h236.29v48.21h-88.961v227.117h-59.113V298.486z"
            />
            <path
              fill="#EC1C24"
              d="M2635.178,249.692h64.663l67.536,105.436l68.134-105.436h66.578l-105.436,154.036v121.875h-57.196V403.729
              	L2635.178,249.692z"
            />
            <path
              fill="#666666"
              d="M860.494,660.131l31.006-3.001c1.87,10.35,5.639,17.948,11.356,22.8c5.692,4.855,13.461,8.306,23.077,7.281
                c8.291-0.876,12.235-2.155,17.443-6.472c5.203-4.321,7.799-9.361,7.799-15.15c0-3.71-1.09-6.869-3.277-9.474
                c-2.201-2.604-6.016-4.866-11.471-6.793c-3.73-1.294-12.241-3.576-25.505-6.858c-17.077-4.21-29.052-9.377-35.949-15.522
                c-9.688-8.633-14.526-19.156-14.526-31.574c0-7.99,2.283-15.461,6.834-22.418c4.556-6.966,11.114-12.261,19.69-15.894
                c8.574-3.644,18.924-5.468,31.05-5.468c19.813,0,34.727,4.326,44.732,12.959c10.016,8.632,15.262,20.148,15.772,34.565
                l-31.868,1.401c-1.376-8.072-4.278-13.871-8.77-17.397c-4.495-3.531-11.216-5.3-20.18-5.3c-9.272,0-16.513,1.886-21.744,5.672
                c-3.374,2.431-5.063,5.672-5.063,9.743c0,3.705,1.577,6.885,4.747,9.524c3.998,3.358,13.774,6.85,29.261,10.487
                c15.512,3.639,26.963,7.41,34.403,11.293c7.417,3.894,13.227,9.208,17.431,15.955c4.189,6.742,6.291,15.074,6.291,24.991
                c0,8.989-2.495,17.407-7.527,25.261c-5.032,7.848-12.125,13.683-21.329,17.499c-9.178,3.817-14.847,5.729-28.56,5.729
                c-19.953,0-35.264-4.582-45.963-13.76C868.961,691.038,862.576,677.688,860.494,660.131z"
            />
            <path
              fill="#666666"
              d="M1057.644,711.188v-66.043l-57.798-90.871h37.343l37.129,62.073l36.382-62.073h36.709l-58.02,91.085v65.829
	              H1057.644z"
            />
            <path
              fill="#666666"
              d="M1159.928,660.131l30.991-3.001c1.865,10.35,5.646,17.948,11.361,22.8c5.692,4.855,13.395,7.281,23.08,7.281
                c10.263,0,17.999-2.155,23.205-6.472c5.2-4.321,7.794-9.361,7.794-15.15c0-3.71-1.088-6.869-3.282-9.474
                c-2.191-2.604-6.01-4.866-11.466-6.793c-3.73-1.294-12.238-3.576-25.498-6.858c-17.082-4.21-29.07-9.377-35.952-15.522
                c-9.69-8.633-14.531-19.156-14.531-31.574c0-7.99,2.275-15.461,6.831-22.418c4.558-6.966,11.125-12.261,19.696-15.894
                c8.574-3.644,18.937-5.468,31.042-5.468c19.821,0,34.731,4.326,44.732,12.959c10.008,8.632,15.268,20.148,15.772,34.565
                l-31.862,1.401c-1.361-8.072-4.293-13.871-8.771-17.397c-4.487-3.531-11.211-5.3-20.177-5.3c-9.257,0-16.508,1.886-21.747,5.672
                c-3.381,2.431-5.063,5.672-5.063,9.743c0,3.705,1.577,6.885,4.734,9.524c4.028,3.358,13.79,6.85,29.273,10.487
                c15.51,3.639,26.981,7.41,34.408,11.293c7.412,3.894,13.229,9.208,17.426,15.955c4.188,6.742,6.306,15.074,6.306,24.991
                c0,8.989-2.518,17.407-7.55,25.261c-5.02,7.848-12.123,13.683-21.298,17.499c-9.186,3.817-20.634,5.729-34.352,5.729
                c-19.938,0-35.256-4.582-45.95-13.76C1168.382,691.038,1162.009,677.688,1159.928,660.131z"
            />
            <path
              fill="#666666"
              d="M1361.677,711.188V580.813h-46.812v-26.539h125.378v26.539h-46.717v130.375H1361.677z"
            />
            <path
              fill="#666666"
              d="M1467.158,711.188V554.273h116.993v26.539h-85.128v34.79h79.207v26.433h-79.207v42.71h88.154v26.442
	              H1467.158z"
            />
            <path
              fill="#666666"
              d="M1616.842,711.188V554.273h47.688l28.635,107.035l28.293-107.035h47.805v156.914h-29.607V587.662
	              l-31.319,123.525h-30.678l-31.213-123.525v123.525H1616.842z"
            />

            <path
              fill="#007DC4"
              d="M820.221,410.159c0,226.663-183.748,410.408-410.4,410.408c-226.661,0-410.409-183.745-410.409-410.408
		            C-0.588,183.504,183.16-0.247,409.82-0.247C636.473-0.247,820.221,183.504,820.221,410.159z"
            />
            <polygon
              fill="#FFFFFF"
              points="319.382,312.286 409.82,531.448 865.845,191.481 409.82,410.159 	"
            />
            <polygon
              fill="#007DC4"
              points="616.8,733.758 865.845,820.527 709.384,575.931 	"
            />
            <path
              fill="#FFFFFF"
              d="M406.373,714.704c-167.932,0-304.547-136.623-304.547-304.55c0-167.928,136.615-304.55,304.547-304.55
                c79.242,0,154.342,30.349,211.434,85.467l9.68,9.354l-9.392,9.646l-46.598,47.943l-9.313,9.576l-9.603-9.277
                c-39.486-38.047-91.417-59.012-146.208-59.012c-116.267,0-210.848,94.599-210.848,210.854
                c0,116.259,94.581,210.865,210.848,210.865c58.405,0,114.728-24.588,154.541-67.476l8.487-9.178l9.746,7.879l52.132,42.107
                l11.015,8.883l-9.458,10.513C575.11,677.891,492.568,714.704,406.373,714.704L406.373,714.704z"
            />
            <path
              fill="#FFFFFF"
              d="M570.728,562.671c-40.954,44.11-99.407,71.751-164.345,71.751c-123.862,0-224.263-100.4-224.263-224.263
                c0-123.853,100.4-224.253,224.263-224.253c60.4,0,115.184,23.926,155.511,62.767l46.6-47.953
                c-52.369-50.546-123.584-81.704-202.111-81.704c-160.8,0-291.145,130.353-291.145,291.143
                c0,160.782,130.345,291.138,291.145,291.138c85.943,0,163.181-37.272,216.466-96.513L570.728,562.671z"
            />
            <path
              fill="#EC1C24"
              d="M779.947,232.677c2.704,5.616,5.28,11.316,7.726,17.077l78.172-58.272L779.947,232.677z"
            />
          </svg>
        </Link>
        <div
          style={{
            justifyContent: 'flex-end',
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {/* <ThemeSwitch /> */}
          <GitHubIcon />
        </div>
      </div>
    </div>
  )
}
