import React from 'react'

import './index.scss'

export const Footer = () => (
  <footer className="footer">
    ©<a href="http://consisty.com/">Consisty System 2020</a>, Built with{' '}
    <a href="https://www.gatsbyjs.org/">gatsby, React</a>
  </footer>
)
